import React from 'react'
import { Container, Segment, Button, Header } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'
import { Link } from '../locales'

const FeedbackThanks = ({ t, i18n, pageContext }) => {
  return (
    <>
      <Segment className='lightgrey' inverted basic vertical>
        <Container textAlign='center'>
          <Segment padded style={{ paddingTop: 40, paddingBottom: 40 }}>
            <Header as='h1'>
              <Trans i18nKey='feedback.thanks.header'>
                <span className='tour-red'>Vielen Dank,</span>
                <br />
                dass Sie sich Zeit genommen haben.
              </Trans>
            </Header>
            <Link to='/' lang={i18n.language}>
              <Button icon labelPosition='right' inverted color='red'>
                {t('feedback.thanks.home')}
                <i className='icon ta-arrow-1-right red link' />
              </Button>
            </Link>
          </Segment>
        </Container>
      </Segment>
    </>
  )
}

export default withTranslation()(FeedbackThanks)
